<template>
    <div>
        <div class="toolBar mBottom10">
            <el-button  plain size='small' style="margin-right: 20px" @click="goBack">返回</el-button>
            <el-input  size='small' placeholder="bean名称" v-model="beanName" style="width: 15%" clearable>
                <i class="el-icon-search el-input__icon" style="cursor: pointer;" slot="suffix"></i>
            </el-input>
            <el-button @click="onSearch" type="primary" size='small' style="margin-left: 10px;">搜索</el-button>
        </div>

        <el-card class="box-card" style="margin-top: 10px">
            <el-table :data="taskLogData"  size='small' stripe :height='tableHeight'>
                <el-table-column type="index" align="center" label='序号'></el-table-column>
                <!--<el-table-column prop="id" label="日志ID" align="left"></el-table-column>-->
                <!--<el-table-column prop="jobId" label="任务ID" align="left"></el-table-column>-->
                <el-table-column prop="beanName" label="bean名称" align="left"></el-table-column>
                <el-table-column prop="methodName" label="方法名称" align="left"></el-table-column>
                <el-table-column prop="params" label="方法参数"align="left"></el-table-column>
                <el-table-column prop="status" label="状态" align="left">
                    <template slot-scope="scope">
                        {{scope.row.status===0?'正常':'暂停'}}
                    </template>
                </el-table-column>
                <el-table-column prop="times" label="耗时（毫秒）" align="left"></el-table-column>
                <el-table-column prop="createTime" label="执行时间" align="left"></el-table-column>
            </el-table>
        </el-card>

        <div class="toolBar clearfix" style="margin-top: 10px">
            <pagi-nation :page='page' @sizeChange='pageSizeChange' @currentChange='pageCurrentChange'></pagi-nation>
        </div>
    </div>
</template>

<script>
    export default {
        // name: "taskLog",
        data(){
            return{
                taskLogData:[],
                page:new this.Page(),
                beanName:null,
                tableHeight: document.documentElement.clientHeight - 288,
            }
        },
        computed : {

        },
        methods:{
            goBack(){
                this.$router.push({name:'timeTask'})
            },
            pageSizeChange(val) {
                this.page.reset();
                this.page.pageSize = val;
                this.load();
            },
            pageCurrentChange(val) {
                this.page.currentPage = val;
                this.load();
            },
            async load() {
                let param = {
                    limit: this.page.pageSize,
                    offSet: (this.page.currentPage - 1) * this.page.pageSize,
                    beanName:this.beanName
                };
                let res = await this.$http.post('/quartz/getScheduleJobLog', param);
                if (res.state) {
                    this.taskLogData = res.data.list;
                    this.page.total = res.data.totalCount;
                }
            },
            onSearch(){
                this.page.reset();
                this.load();
            }
        },
        mounted() {
            this.load();
            window.onresize = () => {
                window.fullHeight = document.documentElement.clientHeight
                this.tableHeight = window.fullHeight - 283;
            }
        }
    }
</script>

<style scoped>

</style>